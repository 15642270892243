import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import PostHeadInfo, { PostHeadInfoProps } from "components/PostHeadInfo";

const PostHeadWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const PostHead: FunctionComponent<PostHeadInfoProps> = function ({
  title,
  date,
  categories,
}) {
  return (
    <PostHeadWrapper>
      <PostHeadInfo title={title} date={date} categories={categories} />
    </PostHeadWrapper>
  );
};

export default PostHead;
