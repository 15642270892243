import React, { FunctionComponent } from "react";
import { graphql } from "gatsby";
import { PostPageItemType } from "../@types/PostItem.types";

import Template from "components/common/Template";
import Header from "components/common/Header";
import PostHead from "components/PostHead";
import PostContent from "components/PostContent";
import Footer from "components/common/Footer";
import Bio from "components/common/Bio";
import Comment from "components/Comment";
import styled from "@emotion/styled";

type PostTemplateProps = {
  data: {
    allMdx: {
      edges: PostPageItemType[];
    };
  };
  location: {
    href: string;
  };
};

const BioWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 70px;
  display: flex;
  justify-content: center;

  img {
    width: 768px;
  }

  @media (max-width: 768px) {
    width: 300px;
    img {
      width: 300px;
    }
  }
`;

const PostTemplate: FunctionComponent<PostTemplateProps> = function ({
  data: {
    allMdx: { edges },
  },
  location: { href },
}) {
  const {
    node: {
      body,
      frontmatter: {
        title,
        summary,
        date,
        categories,
        // thumbnail: { publicURL },
      },
    },
  } = edges[0];

  return (
    <Template title={title} description={summary} url={href}>
      {/* <Header /> */}
      <Header
        style={{
          WebkitBackdropFilter: "blur(2px)",
          backdropFilter: "blur(2px)",
        }}
        title={title}
      />
      <PostHead title={title} date={date} categories={categories} />
      <PostContent body={body} />
      <BioWrapper>
        <Bio />
      </BioWrapper>
      <Comment />
      <Footer />
    </Template>
  );
};

export default PostTemplate;

export const queryMarkdownDataBySlug = graphql`
  query queryMarkdownDataBySlug($slug: String) {
    allMdx(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          body
          frontmatter {
            title
            summary
            date(formatString: "DD MMM YYYY")
            categories
            thumbnail {
              publicURL
            }
          }
        }
      }
    }
  }
`;
