import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

// import BioImage from '../../static/images/BioImg.svg'
import BioImage from "../../static/images/BioImage.svg";

const Background = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;

  @media (max-width: 768px) {
    padding: 0 10px;
    margin-top: 70px;
  }
`;
const BioWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px 0;

  @media (max-width: 768px) {
    img {
      width: 100%;
    }
  }
`;

const Bio = () => {
  return (
    <Background>
      <BioWrapper>
        <Link to={"/"}>
          <img src={BioImage} />
        </Link>
      </BioWrapper>
    </Background>
  );
};

export default Bio;
