import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";

export type PostHeadInfoProps = {
  title: string;
  date: string;
  categories: string[];
};

const PostHeadInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin-top: 10rem;
  margin-bottom: 3rem;
  color: #282828;
  grid-gap: 8px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 9rem 2rem 1.5rem;
    img {
      height: 2.1rem;
    }
  }
`;

const TitleStyle = styled.div`
  overflow: hidden;
  overflow-wrap: break-word;
  margin-top: auto;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 2rem;
  font-weight: 700;
`;

const PostDataStyle = styled.div`
  display: flex;
  top: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    font-weight: 400;
  }
`;
const CategoryWrapperStyle = styled.div`
  display: flex;
  grid-gap: 5px;
`;

const CategoryStyle = styled.div`
  display: flex;
  padding: 4px 10px;
  border-radius: 3px;
  background: #282828;
  font-size: 0.85rem;
  color: white;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const DateStyle = styled.div`
  font-size: 0.85rem;
`;

const PostHeadInfo: FunctionComponent<PostHeadInfoProps> = function ({
  title,
  date,
  categories,
}) {
  return (
    <PostHeadInfoWrapper>
      <PostDataStyle>
        <CategoryWrapperStyle>
          {categories.map((category) => (
            <CategoryStyle key={category}>{category}</CategoryStyle>
          ))}
        </CategoryWrapperStyle>
        <DateStyle>{date}</DateStyle>
      </PostDataStyle>
      <TitleStyle>{title}</TitleStyle>
    </PostHeadInfoWrapper>
  );
};

export default PostHeadInfo;
